import React from "react"
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faLink } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import toast from 'react-hot-toast';

interface ShareProps {
  url: string
}

const Share: React.FC<ShareProps> = (props) => {
  const copyUrl = () => {
    navigator.clipboard.writeText(props.url)
    toast.success('URLをコピーしました')
  }

  return <Root>
    <a
      href={`https://www.facebook.com/sharer/sharer.php?u=${props.url}`}
      target="_blank"
      rel="noreferrer"
      className="share-link facebook"
    >
      <FontAwesomeIcon icon={faFacebookF} />
    </a>
    <a
      href={`https://x.com/intent/tweet?text=${props.url}`}
      target="_blank"
      rel="noreferrer"
      className="share-link twitter"
    >
      <FontAwesomeIcon icon={faTwitter} />
    </a>
    <button className="share-button" onClick={copyUrl}>
      <FontAwesomeIcon icon={faLink} />
    </button>
  </Root>
}

const Root = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  .share-link, .share-button {
    width: 3.5rem;
    height: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    background-color: ${(props) => props.theme.textColor};
    color: ${(props) => props.theme.textLightColor};
    border-radius: 2px;
  }

  .share-button {
    border: none;
    cursor: pointer;
  }

  .facebook {
    background-color: ${(props) => props.theme.facebookColor};
  }

  .twitter {
    background-color: ${(props) => props.theme.twitterColor};
  }
`

export default Share
