import React from "react"
import { PageRendererProps, graphql } from "gatsby"
import { ContentfulPostQuery } from "types/graphql-types"
import styled from "styled-components"
import dayjs from "dayjs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt, faRedoAlt } from "@fortawesome/free-solid-svg-icons"
import SEO from "@/components/SEO"
import Share from "@/components/Share"

type PostPageProps = PageRendererProps & {
  data: ContentfulPostQuery
}

const PostPage: React.FC<PostPageProps> = ({ data, location }) => {
  const post = data.contentfulPost
  if (!post) {
    throw new Error('post not found.')
  }
  const title = post.title ?? undefined
  const excerpt = post.body?.childMarkdownRemark?.excerpt ?? undefined
  const eyecatchUrl = post.eyecatch?.file?.url ? `https:${post.eyecatch?.file?.url}?w=1200` : undefined
  const body = post.body?.childMarkdownRemark?.html ?? ''
  const url = `${process.env.GATSBY_BASE_URL}/posts/${post.slug}`

  return (
    <>
      <SEO
        title={title}
        description={excerpt}
        imageUrl={eyecatchUrl}
        pathname={location.pathname}
      />
      <Root>
        <Post>
          <div className="header">
            {eyecatchUrl && (
              <img src={eyecatchUrl} className="eyecatch" />
            )}
            <h1 className="title">{title}</h1>
            <div className="meta">
              <div className="dates">
                <div className="date">
                  <span className="icon">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </span>
                  {formatDate(post.createdAt)}</div>
                <div className="date">
                  <span className="icon">
                    <FontAwesomeIcon icon={faRedoAlt} />
                  </span>
                  {formatDate(post.updatedAt)}
                </div>
              </div>
              <Share url={url} />
            </div>
            {post.tags?.length &&
              <div className="tags">
                {post.tags.map((tag, index) => (
                  <span className="tag" key={index}>{tag}</span>
                ))}
              </div>
            }
          </div>
          <Body className="body" dangerouslySetInnerHTML={{ __html: body }} />
          <div className="bottom">
            <Share url={url} />
          </div>
        </Post>
      </Root>
    </>
  )
}

function formatDate(date: Date): string {
  return dayjs(date).format('YYYY/MM/DD')
}

export const query = graphql`
  query contentfulPost($slug: String) {
    contentfulPost(slug: {eq: $slug}) {
      id
      eyecatch {
        file {
          url
        }
      }
      slug
      title
      tags
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 90, truncate: true)
        }
      }
      createdAt
      updatedAt
    }
  }
`

const Root = styled.div`
  background-color: ${(props) => props.theme.secondaryLightColor};
  padding: 50px 0 0;

  @media ${(props) => props.theme.minMdUp} {
    padding: 140px 0 60px;
  }
`

const Post = styled.div`
  background-color: #fff;
  margin: 0 auto;
  border-radius: 2px;

  .header {
    padding: 30px;
  }

  .eyecatch {
    width: calc(100% + 60px);
    height: 200px;
    object-fit: contain;
    object-position: center center;
    margin: -30px -30px 30px;
  }

  .title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .dates {
    display: flex;
    padding: 0 0.25rem;
  }

  .date {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
  }

  .date .icon {
    color: ${(props) => props.theme.primaryColor};
    margin-right: 0.5rem;
    font-size: 1rem;
  }

  .dates .date + .date {
    margin-left: 1rem;
  }

  .meta {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .tags {
    margin: 1.25rem -0.25rem 0.25rem;
    display: flex;
    flex-wrap: wrap;
  }

  .tags .tag {
    background-color: ${(props) => props.theme.secondaryLightColor};
    padding: 4px 0.75rem;
    border-radius: 2px;
    font-size: 0.9rem;
    margin: 0.25rem;
  }

  .bottom {
    display: flex;
    justify-content: center;
    padding: 0 30px 50px;
  }

  @media ${(props) => props.theme.minMdUp} {
    width: 750px;

    .eyecatch {
      width: 100%;
      margin: 10px 0 30px;
      height: 350px;
    }

    .title {
      font-size: 2rem;
    }

    .header {
      padding: 50px 30px 40px;
    }

    .meta {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media ${(props) => props.theme.minLgUp} {
    width: 970px;

    .header {
      padding: 50px 60px 40px;
    }
  }
`

const Body = styled.div`
  text-align: justify;
  line-height: 1.75;
  padding: 10px 30px 30px;
  border-top: 3px solid ${(props) => props.theme.secondaryLightColor};
  font-size: 0.9rem;

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1rem;
  }

  h2, h3, h4 {
    margin: 2rem 0 1rem;
    line-height: 1.25;
    font-weight: normal;
    text-align: initial;
  }

  p, pre {
    margin: 1rem 0;
  }

  pre {
    padding: 1rem 0;
  }

  code {
    background-color: ${(props) => props.theme.secondaryLightColor};
    padding: 1rem;
  }

  img {
    display: block;
    margin: 2rem auto;
    max-width: 100%;
  }

  hr {
    margin: 2rem 0;
    height: 3px;
    border-top: 3px solid ${(props) => props.theme.secondaryLightColor};
  }

  a {
    color: ${(props) => props.theme.primaryColor};
    text-decoration: none;
    transition: color .2s;
    word-break: break-all;
  }

  a:hover {
    color: ${(props) => props.theme.secondaryColor};
  }

  blockquote {
    margin: 2rem 0;
    padding-left: 1.5rem;
    color: ${(props) => props.theme.secondaryDarkColor};
    position: relative;
  }

  blockquote:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6px;
    background-color: ${(props) => props.theme.secondaryLightColor};
  }

  ul {
    margin-inline-start: 1rem;
    list-style-type: disc;
  }

  > ul {
    margin-inline-start: 1.5rem;
  }

  li p {
    margin: 0;
  }

  @media ${(props) => props.theme.minMdUp} {
    padding: 20px 30px 30px;
    font-size: 1rem;

    h2 {
      font-size: 1.75rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    h4 {
      font-size: 1.25rem;
    }
  }

  @media ${(props) => props.theme.minLgUp} {
    padding: 20px 60px 30px;
  }
`

export default PostPage
